<!--
File: Plans.vue
Description: Component of Preparatory.vue, shows list of plans
-->
<template>
  <md-card v-if="eligible">
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown v-model="selectedRegion" @input="reloadData" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-10">
          <YearsDropdown v-model="selectedYear" :items="yearsInPlanList" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable  :placeholder="$t('label.search_records')"
              v-model="searchQuery" />
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-10">
          &nbsp;
        </div>
        <div v-if="selectedYear && showPrintBtn" class="md-layout-item md-size-30 btn-row">
          <div class="md-layout-item md-size-25">
            <AmountsDropdown v-model="showAmounts" @input="onAmountChange" />
          </div>
          <md-button v-if="isBtnAllowed('PrintButton')" class="md-success" @click="print">
            {{ $t('buttons.print') }}
          </md-button>
          <md-button v-if="isBtnAllowed('ExportButton')" class="md-success" @click="exporttPlans">
            {{ $t('buttons.excel_export') }}
          </md-button>
        </div>
        <div class="md-layout-item btn-row">
          <md-button v-if="isBtnAllowed('AddButton')" class="md-success" @click="viewItem(null)">
            {{ $t('buttons.add') }}
          </md-button>
        </div>
      </div>
    </md-card-header>
    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" :md-diameter="100" md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}" :class="{ blinking: item.id === highlightedRow }"
          :id="item.id === highlightedRow ? 'highlightedRow' : null">
          <md-table-cell :md-label="$t('road_network.region')">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('stdCols.name')">
            {{ item.name }}
          </md-table-cell>
          <md-table-cell :md-label="$t('budget.year')">
            {{ item.year }}
          </md-table-cell>
          <md-table-cell :md-label="$t('stdCols.created_at')">
            {{ item.created_at | dateFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('budget.works_count')" style="text-align: center;">
            {{ item.assigned_treatments }}
          </md-table-cell>

          <md-table-cell :md-label="$t('label.approved')">
            <div style="display: flex; flex-direction: column;width: fit-content;">
              <span>{{ !item.approved_by_user_id ? $t('buttons.no') : item.approved_on | dateFormat }}</span>
              <md-button
                v-if="isBtnAllowed('ApproveRevokeButton') && (item.assigned_treatments > 0 || item.approved_by_user_id)"
                :class="['md-raised', 'md-sm', item.approved_by_user_id ? 'md-primary' : 'md-success']"
                @click="togglePlanApproval(item.id, item.name, !item.approved_by_user_id)">
                {{ item.approved_by_user_id ? $t('buttons.revoke') : $t('buttons.approve') }}
              </md-button>
            </div>
          </md-table-cell>
          <md-table-cell v-if="showActionsCell" :md-label="$t('tables.actions')">
            <div v-if="!item.approved_by_user_id" class="cell-actions">
              <!---->
              <md-button v-if="isEditAllowed" class="md-just-icon md-success md-simple" @click="viewItem(item)"
                :title="$t('buttons.edit')">
                <md-icon>edit</md-icon>
              </md-button> <!---->

              <md-button v-if="isDeleteAllowed" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                @click.stop.prevent="deleteItem(item.id, item.name)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>

    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>

    <EditForm v-if="showEditForm" :plan="selectedPlan" @close="showEditForm = false" @saved="onPlanSave" />
  </md-card>
</template>
<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import { Pagination } from '@/components'
  import { RegionsDropdown, YearsDropdown, AmountsDropdown } from '@/pages/Components'
  import permissions from "@/mixins/permissionsMixin"
  import messages from '@/mixins/messagesMixin'
  import listView from '@/mixins/listViewMixin'
  import printReport from '@/mixins/printMixin'
  import EditForm from './PlanEditForm.vue'

  import Fuse from 'fuse.js'
  import { numFormat } from "@/store/helpers/format_helpers"
  import ExcelJS from 'exceljs'
  import { saveAs } from 'file-saver'

  export default {
    name: 'Plans',
    mixins: [permissions, listView, messages, printReport],

    data() {
      return {
        formName: 'Plans',
        eligible: false,

        selectedRegion: null,
        selectedYear: null,
        showEditForm: false,
        selectedPlan: null,
        showSpinner: false,

        showAmounts: 1000,
        costLabel: "Cost",

        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['region_description', 'name', 'year'],
        currentSort: 'region_description',
        currentSortOrder: 'asc',
        fuseSearch: null,
      }
    },

    props: {
      step: { default: 'first', type: String },
    },

    components: {
      Pagination,
      RegionsDropdown,
      AmountsDropdown,
      YearsDropdown,
      EditForm
    },

    async mounted() {
      // Check if we are eligible to view the form
      this.eligible = await this.checkIfScreenAllowed()
      if (!this.eligible) {
        this.onClose()
        return
      };

      this.toggleSpinner(true)
      const history = await this.loadHistory()
      if (history.form === 'plans' && history.use) {
        this.selectedRegion = history.data.selectedRegion
        this.selectedYear = history.data.selectedYear
        this.currentSort = history.data.currentSort
        this.currentSortOrder = history.data.currentSortOrder
        this.pagination.perPage = history.data.perPage
        this.$nextTick(() => {
          this.searchQuery = history.data.searchQuery
          this.pagination.currentPage = history.data.currentPage
        })
        this.clearHistory()
      }
      this.onAmountChange(this.showAmounts, this.$t("label.short_thousands"))
      this.toggleSpinner(false)
    },

    methods: {
      ...mapActions({
        loadList: 'Planning/LOAD_PLAN_LIST',
        loadAllWorks: 'LOAD_ALL_WORKS',
        approvePlan: 'Planning/APPROVE_PLAN',
        cancelPlan: 'Planning/CANCEL_PLAN',
        delete: 'Planning/DELETE_PLAN',
        highlightRow: 'HIGHLIGHT_ROW'
      }),

      toggleSpinner(state) {
        this.showSpinner = state
      },

      onAmountChange(value, desc) {
        this.costLabel = `${this.$t('condition.cost')} ${desc}`
      },

      async reloadData() {
        this.toggleSpinner(true)
        await this.loadList({ region_id: this.selectedRegion })
        // Fuse search initialization.
        this.fuseSearch = new Fuse(this.tableData, { keys: this.propsToSearch, threshold: 0.3 })
        this.toggleSpinner(false)
     },

      save_history() {
        const hist = {
          form: 'plans',
          data: {
            selectedRegion: this.selectedRegion,
            selectedYear: this.selectedYear,
            searchQuery: this.searchQuery,
            currentSort: this.currentSort,
            currentSortOrder: this.currentSortOrder,
            perPage: this.pagination.perPage,
            currentPage: this.pagination.currentPage,
          }
        }
        this.saveHistory(hist)
      },

      viewItem(item) {
        this.selectedPlan = item
        this.showEditForm = true
      },

      async onPlanSave() {
        this.showEditForm = false
        await this.reloadData()
      },

      async togglePlanApproval(id, name, status) {
        this.toggleSpinner(true)
        const action = status ? this.approvePlan : this.cancelPlan

        try {
          await action(id)
        } finally {
          this.toggleSpinner(false)
        }
        await this.reloadData()
        await this.approvedMessage(status, name)

        this.highlightRow(id)
      },

      async loadTreatmentsList() {
        const worklist = !this.savedWorksList ? null : this.savedWorksList.find(el => el.year == this.selectedYear)?.work_list_id
        if (!worklist) {
          return
        }

        this.toggleSpinner(true)
        const params = {
          work_list_id: worklist,
          region_id: this.selectedRegion,
          //     is_plan_assigned: 1,
          is_approved_plan_assigned: 1,
          //     is_budget_assigned: 0,
        }
        await this.loadAllWorks(params)
        this.toggleSpinner(false)
      },

      async print() {
        if (!this.selectedYear) return
        this.showSpinner = true

        await this.loadTreatmentsList()

        if (this.treatmentsGroupedByPlan.length == 0) {
          // noting to print
          this.showSpinner = false
          return
        }
        this.printReport('', this.getTableHeaders, this.getTableRows)
        this.showSpinner = false
      },

      fillWorkSheet(workbook, workbookName) {
        const headerCellFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF0070C0' } } // Blue
        const totalCellFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF00' } } // yellow
        const worksheet = workbook.addWorksheet(workbookName)

        // Set the table headers
        worksheet.columns = this.getWorksheetColumns

        // Apply formatting to the table headers
        const headerRow = worksheet.getRow(1);
        headerRow.eachCell({ includeEmpty: true }, (cell) => { cell.fill = headerCellFill })
        headerRow.font = { bold: true, color: { argb: 'FFFFFFFF' } }; // White text
        headerRow.alignment = { vertical: 'middle', horizontal: 'center' };

        // Number formatting for the columns
        worksheet.getColumn('units').numFmt = '#,##0.00'
        worksheet.getColumn('cost').numFmt = '#,##0.00'

        for (const [groupName, group] of Object.entries(this.treatmentsGroupedByPlan)) {
          let row = worksheet.addRow(['', groupName])
          row.font = { bold: true }

          group.forEach(item => {
            worksheet.addRow(item)
          })

          // Group total row
          row = worksheet.addRow(
            this.getWorksheetColumns.map(col => {
              if (col.group) {
                return this.$t('label.total');
              }
              if (col.totals) {
                return this.treatmentsGroupTotals[groupName][col.totals];
              }
              return '';
            })
          );
          row.font = { bold: true };
          row.eachCell({ includeEmpty: true }, (cell) => { cell.fill = totalCellFill });
        }
      },

      async exporttPlans() {
        if (!this.selectedYear) return
        this.showSpinner = true

        await this.loadTreatmentsList()
        if (this.treatmentsGroupedByPlan.length == 0) {
          // nothing to print
          this.showSpinner = false
          return
        }

        const workbook = new ExcelJS.Workbook();
        this.fillWorkSheet(workbook, this.$t('label.plans'))

        // Генерация Excel файла и сохранение
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `${this.$t('label.approved_plans')}-${this.selectedYear}.xlsx`);
      }
    },

    computed: {
      ...mapState({
        savedWorksList: (state) => state.TitleList.worksLists,
        treatmentsList: (state) => state.TitleList.treatmentsList,
        highlightedRow: (state) => state.History.row,

        planList: (state) => state.Planning.plan_list
      }),
      ...mapGetters('Planning', ['planListByYear', 'yearsInPlanList', 'plansApproved']),

      tableData() {
        return this.planListByYear(this.selectedYear)
      },

      isEditAllowed() {
        return this.isBtnAllowed('EditButton')
      },
      isDeleteAllowed() {
        return this.isBtnAllowed('DeleteButton')
      },
      showActionsCell() {
        return this.isEditAllowed || this.isDeleteAllowed
      },

      showPrintBtn() {
        return this.plansApproved(null, this.selectedYear, false).length > 0
      },

      treatmentsGroupedByPlan() {
        let index = 0
        let res = this.treatmentsList
          .sort((a, b) => {
            const planDescA = a.plan_description || ''
            const planDescB = b.plan_description || ''
            let sortRes = planDescA.localeCompare(planDescB, undefined, { sensitivity: 'base' })
            if (sortRes == 0) {
              const descA = a.section_description || ''
              const descB = b.section_description || ''
              sortRes = descA.localeCompare(descB, undefined, { sensitivity: 'base' });
            }
            if (sortRes == 0) { sortRes = a.start_m - b.start_m }
            return sortRes
          })

          .reduce((groups, row) => {
            const group = `${this.$t('label.from_region', { name: row.region_description })}, 
                                          ${this.$t('label.plan_name', { name: row.plan_description })}`
            if (!groups[group]) {
              groups[group] = [];
              index = 0
            }
            //index++
            groups[group].push({
              id: ++index,
              ...row,
              cost: row.cost / this.showAmounts,
            });
            return groups;
          }, {})
        return res
      },

      treatmentsGroupTotals() {
        return Object.keys(this.treatmentsGroupedByPlan).reduce((totals, groupName) => {
          totals[groupName] = {
            totalCost: this.treatmentsGroupedByPlan[groupName].reduce((sum, row) => sum + row.cost, 0),
            //totalUnits: this.treatmentsGroupedByPlan[groupName].reduce((sum, row) => sum + row.units, 0)
          }
          return totals
        }, {});
      },

      getWorksheetColumns() {
        return [
          { header: this.$t('stdCols.id'), key: 'id', width: 5, num: true, digits: 0 },
          { header: this.$t('road_network.section'), key: 'section_description', width: 50, group: true },
          { header: this.$t('inventory.start_distance_m'), key: 'start_m', width: 10, num: true, digits: 0 },
          { header: this.$t('inventory.end_distance_m'), key: 'end_m', width: 10, num: true, digits: 0 },
          { header: this.$t('label.units'), key: 'unit_description', width: 10 },
          { header: this.$t('label.quantity'), key: 'units', width: 10, num: true, digits: 2 },
          { header: this.$t('treatment.treatment_desc'), key: 'treatment_type_description', width: 25 },
          { header: this.costLabel, key: 'cost', width: 20, num: true, digits: 2, totals: 'totalCost' },
        ];
      },

      getTableRows() {
        const columns = this.getWorksheetColumns
        let tableRows = '';

        for (const [groupName, group] of Object.entries(this.treatmentsGroupedByPlan)) {
          // Row with group name
          tableRows += `<tr style="background-color: rgb(255, 255, 0);">
            <td>&nbsp;</td><td colspan=7>${groupName}</td>
          </tr>`
          //          ${columns.map(col => `<td>${col.group ? groupName : '&nbsp;'}</td>`).join('')}

          // rows
          group.forEach((row) => {
            tableRows += `<tr>
              ${columns.map(col => col.num ? `<td class='numCell'>${numFormat(row[col.key], col.digits)}</td>`
              : `<td>${row[col.key]}</td>`).join('')}
              </tr>`;
          });

          // Group total row
          const cols = columns.map(col => {
            if (col.group) {
              return `<td>${this.$t('label.total')}</td>`;
            }

            if (col.totals) {
              return `<td class="numCell">${numFormat(this.treatmentsGroupTotals[groupName][col.totals])}</td>`;
            }

            return '<td>&nbsp;</td>';
          }).join('')
          tableRows += `<tr style="background-color: rgb(204, 221, 255);">${cols}</tr>`;
        }
        return tableRows;
      },
    },

    watch: {
      step(value) {
        if (value === 'third') this.reloadData()
      },

      async highlightedRow(newVal) {
        if (newVal) {
          await this.reloadData();
          // calculate - which page edited item belongs to
          const ind = this.searchedData.findIndex(item => item.id == newVal)
          this.pagination.currentPage = Math.ceil(ind / this.pagination.perPage) || 1
          this.scrollToHighlightedRow();
          this.clearHistory();
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: flex-end;

}

.md-button {
  margin: 3px 0;
  min-width: 100px;
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-just-icon {
  margin: 3px 0;
  min-width: 10px;
}

.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.paginated-table table>tbody>tr>td {
  padding: 0px !important;
  outline: 0;
  width: 150px;
  border-right: 0;
  border-bottom: 0;
}

.md-button+.md-button {
  margin-left: 10px;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}

.blinking {
  animation: blink-animation 1s linear infinite;
}

@keyframes blink-animation {
  50% {
    background-color: pink;
  }
}
</style>